<template>
  <div>
    <div class="commonCardsCourse" data-testid="common-cards-course">

      <div class="commonCardsCourse-content" :class="{ blocked: blocked }">
        
        <!-- SELO -->
        <div class="label" v-if="blocked">
          <div class="label__svg">
              <IconSax name="lock" color="var(--neutral-gray-900)" />
          </div>
        </div>

        <!-- IMAGE -->
        <Cover  :cover="data && data.cover ? data.cover : {}"/>
        
        <!-- HOVER -->
        <div class="hover">
          <template v-if="blocked">
            <Blocked type="course" @show="denyCourse(data)" />
          </template>
          <template v-else>
            <Allowed type="course" @edit="$emit('edit')" @show="$emit('show')" />
          </template>
        </div>
      </div>
    </div>
    <Title :title="data.title" :allow="blocked"  />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Blocked from "@/components/common/cards/messages/Blocked.vue";
import Allowed from "@/components/common/cards/messages/Allowed.vue";
import Title from "@/components/common/cards/title/Title.vue";
import Cover from "@/components/common/cards/cover/Cover.vue";

export default {
  computed: {
    blocked() {
      return this.data.has_access === false || this.data.has_access === true && this.data.is_liberated === false;
    }
  },
  components: {
    Blocked,
    Allowed,
    Cover,
    Title
  },
  methods: {
    ...mapActions({
      actionUpdateCoursesList: "home/actionUpdateCoursesList",
      actionFetchHomeData: "home/actionFetchHomeData",
      actionToggleRearrangeCourse: "home/actionToggleRearrangeCourse",
      actionRearrangeOrderCourse: "home/actionRearrangeOrderCourse",
      actionFetchCourseAvailability: "home/actionFetchCourseAvailability",
      actionShowModalInfoCourse: "home/actionShowModalInfoCourse",
      actionShowModalBuyCourse: "home/actionShowModalBuyCourse",
    }),

    async denyCourse(item) {   
      
      if(item.has_access === false) {
        const response = await this.actionFetchCourseAvailability(item?.id);

        const data = {
          id: response.id,
          title: response.title,
          cover: response.cover,
          button: response.sale_button_txt,
          description: response.sale_description,
          link: response.sale_link,
          video: response.sale_video,
          video_thumb: response.sale_video_thumb,
          video_type: response.sale_video_type
        }

        this.actionShowModalBuyCourse({
          active: true,
          data
        })
      }

      if(item.has_access === true && item.is_liberated === false) {
    
        const data = {
          rule: item.rule_liberation,
          date: item.liberated_at,
          type: 1
        }

        this.actionShowModalInfoCourse({
          active: true,
          data
        })
      }
    }
  },
  props: {
    data: {
      type: Object
    },
  },
};
</script>

<style lang="scss" scoped>
.commonCardsCourse {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;

  &-title {
    margin-top: 8px;
    color: var(--fontcolor);
    @extend .body-semibold-14;

    &.blocked {
      color: var(--neutral-gray-500);
    }
  }

  &-content {
    position: relative;
    cursor: pointer;

    &.blocked {
      
    }
    .hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    img {
      width: 100%;
      height: auto;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &:hover {
    .commonCardsCourse-content .hover {
      opacity: 1;
    }
  }

  .label {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #FFF;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    transition: opacity 0.3s ease;

    &__svg {
      position: relative;
      left: -7px;
      top: 7px;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFF;
      border-radius: 50%;

      ::v-deep .svgCss svg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        & path {
          fill: var(--neutral-black);
        }
      }
    }
  }
}
</style>
